<!-- 修改昵称 -->
<template>
  <div class="ModifyNickName">
    <van-field
      class="c_input"
      v-model="nickName"
      center
      clearable
      placeholder="请输入"
      maxlength="10"
      :formatter="formatter"
    />
    <div v-show="nickName" class="c_redBtn" @click="saveName">保存</div>
    <div v-show="!nickName" class="c_redBtn c_disBtn">保存</div>
  </div>
</template>

<script>
import { updateUserInfo } from "@/api/api_personal";
export default {
  name: "ModifyNickName",
  data() {
    return {
      nickName: this.$route.query.nickName
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.loginInfo;
    }
  },
  methods: {
    formatter(value) {
      const reg = /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/;
      return reg.test(value) ? value : value.substr(0, value.length - 1);
    },
    saveName() {
      updateUserInfo(
        {
          userId: this.userInfo.userId,
          nickName: this.nickName
        },
        { headers: { token: this.userInfo.token } }
      ).then(res => {
        if (res.code === 0) {
          this.$router.back(-1);
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.ModifyNickName {
  .c_redBtn {
    margin: 0.3rem 0.18rem;
  }
}
</style>
